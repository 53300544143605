import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, signInWithCustomToken } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { msalInstance } from '../auth/msal';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// Connect to emulators in development
if (process.env.NODE_ENV === 'development') {
  const EMULATOR_HOST = 'localhost';

  // Connect to Firestore emulator
  connectFirestoreEmulator(db, EMULATOR_HOST, 8080);

  // Connect to Functions emulator
  connectFunctionsEmulator(functions, EMULATOR_HOST, 5001);

  // Connect to Auth emulator
  connectAuthEmulator(auth, `http://${EMULATOR_HOST}:9099`, { disableWarnings: true });

  // Connect to Storage emulator
  connectStorageEmulator(storage, EMULATOR_HOST, 9199);

  console.log('🔧 Using Firebase emulators');
  window.db = db; // For debugging in console
}

export const initializeFirebaseAuth = async msalAccount => {
  try {
    if (!msalAccount) {
      console.log('No MSAL account found');
      return null;
    }

    // Get MSAL token
    const tokenResponse = await msalInstance.acquireTokenSilent({
      account: msalAccount,
      scopes: ['User.Read'],
    });

    // Get Firebase token from Cloud Function
    const getFirebaseToken = httpsCallable(functions, 'getFirebaseToken');
    const result = await getFirebaseToken({ msalToken: tokenResponse.accessToken });

    // Sign in to Firebase with custom token
    await signInWithCustomToken(auth, result.data.firebaseToken);

    // Wait for auth state to be ready
    return new Promise(resolve => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe(); // Stop listening once we get the user
        resolve({ firebaseUser: user });
      });
    });
  } catch (error) {
    console.error('❌ Firebase auth initialization error:', {
      name: error.name,
      message: error.message,
      code: error.code,
      details: error.details,
      stack: error.stack,
    });
    throw error;
  }
};

export { app, auth, db, functions, storage };
