/**
 * @component DashboardView
 * @description Presentation component for the dashboard.
 * Displays user and project analytics in various views.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  Person as PersonIcon,
  Business as BusinessIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import StatCard from '../common/StatCard';
import {
  People as PeopleIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../contexts/toast';

const commonCellStyle = {
  padding: '6px 16px',
  minWidth: '60px',
  textAlign: 'center',
  borderBottom: '1px solid rgba(224, 224, 224, 1)', // MUI's default border color
};

const DashboardView = ({
  loading,
  kpiData,
  lifetimeKpiData,
  viewMode,
  viewType,
  selectedDate,
  isLifetime,
  onViewModeChange,
  onViewTypeChange,
  onDateChange,
  onLifetimeToggle,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();

  // Add state for expanded rows
  const [expandedRows, setExpandedRows] = useState(new Set());

  // Add toggle handler
  const handleRowExpand = rowId => {
    setExpandedRows(prev => {
      const next = new Set(prev);
      if (next.has(rowId)) {
        next.delete(rowId);
      } else {
        next.add(rowId);
      }
      return next;
    });
  };

  const formatUserName = email => {
    if (!email) return '';

    // Get the part before @
    const [name] = email.split('@');

    // Split by dot or underscore and capitalize each part
    const parts = name
      .split(/[._]/)
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());

    // Join with space
    return parts.join(' ');
  };

  // Add the missing functions
  const handleDateChange = direction => {
    if (isLifetime) return;

    let newDate = new Date(selectedDate);

    // Always navigate by year regardless of view mode
    if (direction === 'prev') {
      newDate.setFullYear(newDate.getFullYear() - 1);
    } else if (direction === 'next') {
      newDate.setFullYear(newDate.getFullYear() + 1);
    }

    onDateChange(newDate, direction);
  };

  const renderDateNavigation = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {/* Left arrow */}
      <IconButton
        onClick={() => handleDateChange('prev')}
        disabled={isLifetime}
        aria-label="Previous period"
        data-testid="prev-period-button"
        size="small"
      >
        <ChevronLeftIcon />
      </IconButton>

      {/* Current period display */}
      <Typography variant="h6" sx={{ minWidth: '200px', textAlign: 'center' }}>
        {getDisplayDate()}
      </Typography>

      {/* Right arrow */}
      <IconButton
        onClick={() => handleDateChange('next')}
        disabled={isLifetime}
        aria-label="Next period"
        data-testid="next-period-button"
        size="small"
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );

  const getDisplayDate = () => {
    if (isLifetime) {
      return t('dashboard.lifetime');
    }

    // Always display year only
    return format(selectedDate, 'yyyy');
  };

  // Share URL button
  const handleShareClick = () => {
    const url = `${window.location.origin}${window.location.pathname}${location.search}`;
    navigator.clipboard.writeText(url).then(() => {
      showToast(t('dashboard.url_copied'), 'success');
    });
  };

  if (!kpiData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Controls */}
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                mb: 3,
                flexWrap: 'wrap',
                '& > *': { flexShrink: 0 },
                width: '100%',
                maxWidth: '1200px',
              }}
            >
              {/* View Mode Toggle */}
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(e, value) => value && onViewModeChange(value)}
                size="small"
              >
                <ToggleButton value="weekly">{t('dashboard.weekly')}</ToggleButton>
                <ToggleButton value="monthly">{t('dashboard.monthly')}</ToggleButton>
                {/* <ToggleButton value="yearly">{t('dashboard.yearly')}</ToggleButton> */}
              </ToggleButtonGroup>

              {/* Date Navigation */}
              {renderDateNavigation()}

              {/* Add Share button */}
              <IconButton
                onClick={handleShareClick}
                size="small"
                sx={{ ml: 'auto' }}
                title={t('dashboard.share_view')}
              >
                <ShareIcon />
              </IconButton>
            </Box>

            {/* No Data Message */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" color="text.secondary">
                {t('dashboard.no_data_for_year', { year: format(selectedDate, 'yyyy') })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Controls */}
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                mb: 3,
                flexWrap: 'wrap',
                '& > *': { flexShrink: 0 },
                width: '100%',
                maxWidth: '1200px',
              }}
            >
              {/* View Mode Toggle */}
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(e, value) => value && onViewModeChange(value)}
                size="small"
              >
                <ToggleButton value="weekly">{t('dashboard.weekly')}</ToggleButton>
                <ToggleButton value="monthly">{t('dashboard.monthly')}</ToggleButton>
                {/* <ToggleButton value="yearly">{t('dashboard.yearly')}</ToggleButton> */}
              </ToggleButtonGroup>

              {/* Date Navigation */}
              {renderDateNavigation()}

              {/* Add Share button */}
              <IconButton
                onClick={handleShareClick}
                size="small"
                sx={{ ml: 'auto' }}
                title={t('dashboard.share_view')}
              >
                <ShareIcon />
              </IconButton>
            </Box>

            {/* Loading Indicator */}
            <Box sx={{ mt: 4 }}>
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  const getDataForView = () => {
    const data = isLifetime ? lifetimeKpiData : kpiData;
    if (!data) return null;

    if (viewType === 'users') {
      return data.users || {};
    } else {
      return data.projects || {};
    }
  };

  const renderKPICards = () => {
    const data = isLifetime ? lifetimeKpiData : kpiData;

    if (!data) {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<PeopleIcon />}
            title={t('dashboard.stats.total_users')}
            value={data.totalUsers || 0}
            subtitle={t('dashboard.stats.active_this_month')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<AssignmentIcon />}
            title={t('dashboard.stats.total_timesheets')}
            value={data.totalTimesheets || 0}
            subtitle={t('dashboard.stats.submitted_this_month')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard
            icon={<TrendingUpIcon />}
            title={t('dashboard.stats.completion_rate')}
            value={`${data.completionRate || 0}%`}
            subtitle={t('dashboard.stats.average_completion')}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDetailTable = () => {
    const data = getDataForView();
    if (!data) return null;

    // Helper function to get the correct value based on view mode
    const getMissionValue = mission => {
      if (!mission) return 0;

      if (viewMode === 'monthly' && mission.monthlyData) {
        const monthKey = format(selectedDate, 'yyyy-MM');
        return mission.monthlyData[monthKey] || 0;
      } else if (viewMode === 'weekly' && mission.weeklyData) {
        const weekKey = `${format(selectedDate, 'yyyy')}-W${format(selectedDate, 'II')}`;
        return mission.weeklyData[weekKey] || 0;
      }
      return 0;
    };

    return (
      <TableContainer component={Paper}>
        <Table data-testid="dashboard-detail-table">
          <TableHead>
            <TableRow>
              <TableCell data-testid="table-header-name">
                {viewType === 'users' ? t('dashboard.table.user') : t('dashboard.table.project')}
              </TableCell>
              <TableCell align="right" data-testid="table-header-days">
                {t('dashboard.table.total_days')}
              </TableCell>
              <TableCell align="right" data-testid="table-header-count">
                {viewType === 'users'
                  ? t('dashboard.table.projects')
                  : t('dashboard.table.missions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([id, item]) => (
              <React.Fragment key={id}>
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton size="small" onClick={() => handleRowExpand(id)} sx={{ mr: 1 }}>
                        {expandedRows.has(id) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      </IconButton>
                      <Box data-testid={`users-cell-${id}`}>
                        {viewType === 'users' ? formatUserName(id) : item.name}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">{getMissionValue(item).toFixed(1)}</TableCell>
                  <TableCell align="right">
                    {viewType === 'users'
                      ? item.missions.length
                      : Object.keys(item.missions).length}
                  </TableCell>
                </TableRow>
                {viewType === 'users' && expandedRows.has(id) && (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ py: 0 }}>
                      <Box sx={{ pl: 4 }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('dashboard.table.mission')}</TableCell>
                              <TableCell>{t('dashboard.table.project')}</TableCell>
                              <TableCell align="right">{t('dashboard.table.total_days')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(item.missionDetails).map(([missionId, mission]) => (
                              <TableRow key={missionId}>
                                <TableCell data-testid={`mission-name-${missionId}`}>
                                  {mission.name}
                                </TableCell>
                                <TableCell>{mission.projectName}</TableCell>
                                <TableCell align="right" data-testid={`mission-time-${missionId}`}>
                                  {getMissionValue(mission).toFixed(1)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTimelineData = () => {
    const data = getDataForView();
    if (!data) return null;

    const timeData = Object.entries(data).map(([id, item]) => ({
      id,
      name: viewType === 'users' ? id : item.name,
      data: viewMode === 'weekly' ? item.weeklyData : item.monthlyData,
    }));

    return (
      <Box sx={{ mt: 3 }}>
        {timeData.map(({ id, name, data }) => (
          <Accordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                {viewType === 'users' ? (
                  <>
                    <PersonIcon sx={{ mr: 1 }} />
                    {formatUserName(name)}
                  </>
                ) : (
                  <>
                    <BusinessIcon sx={{ mr: 1 }} />
                    {name}
                  </>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.entries(data || {}).map(([period, value]) => (
                  <Chip
                    key={period}
                    label={`${period}: ${value.toFixed(1)}`}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  // // Get all periods based on view mode
  // const getAllPeriods = () => {
  //   if (isLifetime) {
  //     // Get all years from current year to 5 years back
  //     const currentYear = new Date().getFullYear();
  //     return Array.from({ length: 6 }, (_, i) => (currentYear - i).toString()).reverse();
  //   }

  //   if (viewMode === 'monthly') {
  //     // Generate all months for the selected year
  //     const year = selectedDate.getFullYear();
  //     return Array.from({ length: 12 }, (_, i) => {
  //       const month = (i + 1).toString().padStart(2, '0');
  //       return `${year}-${month}`;
  //     });
  //   }

  //   if (viewMode === 'weekly') {
  //     // Generate all weeks for the selected year
  //     const year = selectedDate.getFullYear();
  //     const lastWeek = getISOWeeksInYear(new Date(year, 0, 1));

  //     // Generate weeks for current year
  //     const currentYearWeeks = Array.from({ length: lastWeek }, (_, i) => {
  //       const weekNum = (i + 1).toString().padStart(2, '0');
  //       return `${year}-W${weekNum}`;
  //     });

  //     // Add Week 1 of next year if we have data for it
  //     const nextYear = year + 1;
  //     const nextYearWeek1 = `${nextYear}-W01`;

  //     return [...currentYearWeeks, nextYearWeek1];
  //   }

  //   return [];
  // };

  // // Helper to format period labels
  // const formatPeriodLabel = period => {
  //   if (isLifetime) return period; // Year is already formatted

  //   if (viewMode === 'monthly') {
  //     // Convert YYYY-MM to month name
  //     const [year, month] = period.split('-');
  //     return format(new Date(parseInt(year), parseInt(month) - 1), 'MMM');
  //   }

  //   if (viewMode === 'weekly') {
  //     // Just return the week number without "Week" prefix
  //     const weekNum = period.split('-W')[1];
  //     return weekNum;
  //   }

  //   return period;
  // };

  // const getTimeData = item => {
  //   if (isLifetime) {
  //     return item.yearlyData;
  //   }
  //   return viewMode === 'weekly' ? item.weeklyData : item.monthlyData;
  // };

  const renderMatrixTable = () => {
    const data = getDataForView();
    if (!data) return null;

    // Get periods based on view mode
    const periods =
      viewMode === 'weekly'
        ? Array.from({ length: 53 }, (_, i) => i + 1) // Weeks 1-53
        : Array.from({ length: 12 }, (_, i) => i + 1); // Months 1-12

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...commonCellStyle, textAlign: 'left', minWidth: '200px' }}>
                {viewType === 'users' ? t('common.user') : t('common.project')}
              </TableCell>
              {periods.map(period => (
                <TableCell key={period} sx={commonCellStyle}>
                  {viewMode === 'weekly'
                    ? `W${period.toString().padStart(2, '0')}`
                    : format(new Date(selectedDate.getFullYear(), period - 1), 'MMM')}
                </TableCell>
              ))}
              <TableCell sx={commonCellStyle}>{t('common.total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([id, item]) => (
              <React.Fragment key={id}>
                {/* Main row */}
                <TableRow
                  hover
                  sx={{
                    '& > *': { borderBottom: expandedRows.has(id) ? 'none' : undefined },
                    cursor: 'pointer',
                  }}
                >
                  <TableCell sx={{ ...commonCellStyle, textAlign: 'left' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <IconButton
                        size="small"
                        onClick={e => {
                          e.stopPropagation();
                          handleRowExpand(id);
                        }}
                      >
                        {expandedRows.has(id) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                      </IconButton>
                      <Typography>
                        {viewType === 'users' ? formatUserName(id) : item.name}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Monthly cells */}
                  {periods.map(period => {
                    const periodKey =
                      viewMode === 'weekly'
                        ? `${selectedDate.getFullYear()}-W${period.toString().padStart(2, '0')}`
                        : `${selectedDate.getFullYear()}-${period.toString().padStart(2, '0')}`;

                    const value =
                      viewMode === 'weekly'
                        ? item.weeklyData?.[periodKey]
                        : item.monthlyData?.[periodKey];

                    const timesheetId =
                      viewType === 'users' && viewMode === 'monthly'
                        ? item.monthlyTimesheets?.[periodKey]
                        : null;

                    return (
                      <TableCell
                        key={period}
                        sx={{
                          ...commonCellStyle,
                          cursor: timesheetId ? 'pointer' : 'default',
                          '&:hover': timesheetId
                            ? {
                                backgroundColor: 'action.hover',
                              }
                            : undefined,
                        }}
                        onClick={() => timesheetId && navigate(`/timesheet/edit/${timesheetId}`)}
                      >
                        {value || '-'}
                      </TableCell>
                    );
                  })}
                  <TableCell sx={commonCellStyle}>{item.totalDays || '-'}</TableCell>
                </TableRow>

                {/* Expanded content */}
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={periods.length + 2}>
                    <Collapse in={expandedRows.has(id)} timeout="auto" unmountOnExit>
                      <Box sx={{ pl: 4 }}>
                        {viewType === 'users' ? (
                          // Show missions for user view
                          <Table size="small" sx={{ tableLayout: 'fixed' }}>
                            <TableBody>
                              {Object.entries(item.missionDetails || {}).map(
                                ([missionId, mission]) => (
                                  <TableRow key={missionId}>
                                    <TableCell
                                      sx={{
                                        ...commonCellStyle,
                                        textAlign: 'left',
                                        pl: 0,
                                        width: '200px',
                                      }}
                                    >
                                      {mission.name} - {mission.projectName}
                                    </TableCell>
                                    {periods.map(period => {
                                      const periodKey =
                                        viewMode === 'weekly'
                                          ? `${selectedDate.getFullYear()}-W${period.toString().padStart(2, '0')}`
                                          : `${selectedDate.getFullYear()}-${period.toString().padStart(2, '0')}`;

                                      return (
                                        <TableCell
                                          key={period}
                                          sx={{
                                            ...commonCellStyle,
                                            width: '60px',
                                          }}
                                        >
                                          {viewMode === 'weekly'
                                            ? mission.weeklyData?.[periodKey] || '-'
                                            : mission.monthlyData?.[periodKey] || '-'}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell
                                      sx={{
                                        ...commonCellStyle,
                                        width: '60px',
                                      }}
                                    >
                                      {Object.values(mission.monthlyData || {})
                                        .reduce((sum, val) => sum + (val || 0), 0)
                                        .toFixed(1) || '-'}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          // Show users for project view
                          <Table size="small" sx={{ tableLayout: 'fixed' }}>
                            <TableBody>
                              {item.timesheets?.map(timesheet => (
                                <TableRow key={timesheet.userEmail}>
                                  <TableCell sx={{ ...commonCellStyle, textAlign: 'left' }}>
                                    {formatUserName(timesheet.userEmail)}
                                  </TableCell>
                                  {periods.map(period => {
                                    const periodKey =
                                      viewMode === 'weekly'
                                        ? `${selectedDate.getFullYear()}-W${period.toString().padStart(2, '0')}`
                                        : `${selectedDate.getFullYear()}-${period.toString().padStart(2, '0')}`;

                                    return (
                                      <TableCell key={period} sx={commonCellStyle}>
                                        {viewMode === 'weekly'
                                          ? timesheet.weeklyData?.[periodKey] || '-'
                                          : timesheet.monthlyData?.[periodKey] || '-'}
                                      </TableCell>
                                    );
                                  })}
                                  <TableCell sx={commonCellStyle}>
                                    {Object.values(timesheet.monthlyData || {})
                                      .reduce((sum, val) => sum + (val || 0), 0)
                                      .toFixed(1) || '-'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > *:not(:first-of-type)': {
            // Apply maxWidth to all children except the first one (controls)
            maxWidth: '1200px',
            width: '100%',
          },
        }}
      >
        {/* Controls */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mb: 3,
            flexWrap: 'wrap',
            '& > *': { flexShrink: 0 },
            width: '100%',
            maxWidth: '1200px',
          }}
        >
          {/* View Mode Toggle with Lifetime */}
          <ToggleButtonGroup
            value={isLifetime ? 'yearly' : viewMode}
            exclusive
            onChange={(e, value) => {
              if (value === 'yearly') {
                onLifetimeToggle(true);
              } else if (value) {
                onLifetimeToggle(false);
                onViewModeChange(value);
              }
            }}
            size="small"
          >
            <ToggleButton
              value="weekly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.weekly')}
            </ToggleButton>

            <ToggleButton
              value="monthly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.monthly')}
            </ToggleButton>
            {/* <ToggleButton
              value="yearly"
              sx={{
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                ml: 1,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                },
              }}
            >
              {t('dashboard.yearly')}
            </ToggleButton> */}
          </ToggleButtonGroup>

          {/* Date Navigation */}
          {renderDateNavigation()}

          {/* Add Share button */}
          <IconButton
            onClick={handleShareClick}
            size="small"
            sx={{ ml: 'auto' }}
            title={t('dashboard.share_view')}
          >
            <ShareIcon />
          </IconButton>
        </Box>

        {/* KPI Cards */}
        <Box sx={{ mb: 6 }}>{renderKPICards()}</Box>

        {/* View Type Toggle */}
        <Box sx={{ mb: 3 }}>
          <ToggleButtonGroup
            value={viewType}
            exclusive
            onChange={(e, value) => value && onViewTypeChange(value)}
            size="small"
          >
            <ToggleButton value="users">{t('dashboard.view_type.users')}</ToggleButton>
            <ToggleButton value="projects">{t('dashboard.view_type.projects')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Matrix Table */}
        {renderMatrixTable()}

        {/* Other components */}
        {viewType === 'users' && renderDetailTable()}
        {viewType === 'users' && renderTimelineData()}
      </Box>
    </Box>
  );
};

DashboardView.propTypes = {
  loading: PropTypes.bool.isRequired,
  kpiData: PropTypes.object,
  lifetimeKpiData: PropTypes.object,
  viewMode: PropTypes.oneOf(['monthly', 'weekly']).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  viewType: PropTypes.oneOf(['users', 'projects']).isRequired,
  isLifetime: PropTypes.bool.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onLifetimeToggle: PropTypes.func.isRequired,
};

export default DashboardView;
