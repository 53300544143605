import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  Alert,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase/config';
import { useTranslation } from 'react-i18next';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';

const BugReport = () => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [report, setReport] = useState({
    title: '',
    description: '',
    steps: '',
    expectedBehavior: '',
    actualBehavior: '',
  });

  const handleScreenshotChange = e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setScreenshot(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveScreenshot = () => {
    setScreenshot(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      let screenshotUrl = null;

      if (screenshot) {
        try {
          const fileName = `${Date.now()}-${screenshot.name}`;
          const screenshotRef = ref(storage, `bug-reports/${fileName}`);

          console.log('Starting upload...', {
            fileName,
            fileSize: screenshot.size,
            fileType: screenshot.type,
          });

          const metadata = {
            contentType: screenshot.type,
          };

          const uploadResult = await uploadBytes(screenshotRef, screenshot, metadata);
          console.log('Upload successful:', uploadResult);

          screenshotUrl = await getDownloadURL(uploadResult.ref);
          console.log('Download URL:', screenshotUrl);
        } catch (uploadError) {
          console.error('Upload error details:', {
            code: uploadError.code,
            message: uploadError.message,
            serverResponse: uploadError.serverResponse,
            name: uploadError.name,
          });
          showToast(t('bugReport.messages.upload_error'), 'error');
        }
      }

      // Add browser and system info
      const browserInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        url: window.location.href,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
      };

      // Create bug report document
      await addDoc(collection(db, 'bugReports'), {
        ...report,
        browserInfo,
        screenshotUrl,
        reportedBy: {
          email: user?.email || 'anonymous',
          role: user?.role || 'anonymous',
        },
        status: 'new',
        createdAt: serverTimestamp(),
      });

      showToast(t('bugReport.messages.submit_success'), 'success');
      // Reset form
      setReport({
        title: '',
        description: '',
        steps: '',
        expectedBehavior: '',
        actualBehavior: '',
      });
      handleRemoveScreenshot();
    } catch (error) {
      console.error('Error submitting bug report:', error);
      showToast(t('bugReport.messages.submit_error'), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('bugReport.title')}
        </Typography>

        <Alert severity="info" sx={{ mb: 3 }}>
          {t('bugReport.info')}
        </Alert>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            required
            label={t('bugReport.fields.title')}
            margin="normal"
            value={report.title}
            onChange={e => setReport({ ...report, title: e.target.value })}
            disabled={loading}
          />

          <TextField
            fullWidth
            required
            multiline
            rows={4}
            label={t('bugReport.fields.description')}
            margin="normal"
            value={report.description}
            onChange={e => setReport({ ...report, description: e.target.value })}
            disabled={loading}
            helperText={t('bugReport.fields.description_help')}
          />

          <TextField
            fullWidth
            required
            multiline
            rows={3}
            label={t('bugReport.fields.steps')}
            margin="normal"
            value={report.steps}
            onChange={e => setReport({ ...report, steps: e.target.value })}
            disabled={loading}
            helperText={t('bugReport.fields.steps_help')}
          />

          <TextField
            fullWidth
            multiline
            rows={2}
            label={t('bugReport.fields.expected_behavior')}
            margin="normal"
            value={report.expectedBehavior}
            onChange={e => setReport({ ...report, expectedBehavior: e.target.value })}
            disabled={loading}
          />

          <TextField
            fullWidth
            multiline
            rows={2}
            label={t('bugReport.fields.actual_behavior')}
            margin="normal"
            value={report.actualBehavior}
            onChange={e => setReport({ ...report, actualBehavior: e.target.value })}
            disabled={loading}
          />

          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t('bugReport.fields.screenshot')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                border: '1px dashed',
                borderColor: 'divider',
                borderRadius: 1,
                p: 2,
              }}
            >
              <input
                accept="image/*"
                type="file"
                id="screenshot-upload"
                onChange={handleScreenshotChange}
                style={{ display: 'none' }}
                disabled={loading}
              />
              <label htmlFor="screenshot-upload">
                <Button
                  component="span"
                  startIcon={<AddPhotoAlternateIcon />}
                  disabled={loading}
                  variant="outlined"
                >
                  {t('bugReport.fields.add_screenshot')}
                </Button>
              </label>
              {previewUrl && (
                <Box sx={{ position: 'relative', maxWidth: 200 }}>
                  <img
                    src={previewUrl}
                    alt="Screenshot preview"
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: 4 }}
                  />
                  <IconButton
                    size="small"
                    onClick={handleRemoveScreenshot}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      bgcolor: 'background.paper',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>

          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            disabled={loading || !report.title || !report.description || !report.steps}
          >
            {loading ? <CircularProgress size={24} /> : t('bugReport.submit')}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default BugReport;
