import React from 'react';
import PropTypes from 'prop-types';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';

// Import all components
import CoverPage from './components/CoverPage';
import Footer from './components/Footer';
import ConsultantSummaryTable from './components/tables/ConsultantSummaryTable';
import ConsultantTimesheet from './components/tables/ConsultantTimesheet';
import SignaturePage from './components/SignaturePage';
import WeeklyCalendarView from './components/WeeklyCalendarView';

const styles = StyleSheet.create({
  page: {
    padding: '20mm',
    backgroundColor: 'white',
    position: 'relative',
    minHeight: '297mm',
  },
  consultantPairContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  consultantContainer: {
    flex: 1,
    marginBottom: '10mm',
  },
  firstConsultant: {
    marginBottom: '20mm',
  },
  consultantSeparator: {
    borderBottom: '1pt solid #000',
    width: '100%',
    marginVertical: '10mm',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  summaryPage: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10mm',
  },
  summaryTable: {
    marginBottom: '10mm',
  },
  weeklyView: {
    flex: 1,
  },
});

const aggregateConsultantData = timesheets => {
  const aggregatedDays = {};

  console.log('Aggregating timesheet data:', timesheets);

  timesheets.forEach(({ days }) => {
    console.log('Processing timesheet days:', days);

    days.forEach(({ date, value }) => {
      console.log('Processing day:', { date, value });

      if (!aggregatedDays[date]) {
        aggregatedDays[date] = 0;
      }
      aggregatedDays[date] += value;
    });
  });

  const result = Object.entries(aggregatedDays).map(([date, value]) => ({ date, value }));
  console.log('Aggregated result:', result);

  return result;
};

const TimeSheetPDFTemplate = ({ projectData, selectedDate }) => {
  const periodStart = startOfMonth(selectedDate);
  const periodEnd = endOfMonth(selectedDate);

  // Group consultants into pairs
  const consultantPairs = projectData.timesheets.reduce((pairs, timesheet, index) => {
    const pairIndex = Math.floor(index / 2);
    if (!pairs[pairIndex]) {
      pairs[pairIndex] = [];
    }
    pairs[pairIndex].push(timesheet);
    return pairs;
  }, []);

  // Calculate total pages: Cover + Summary + Consultant pairs + Signatures
  const totalPages = 2 + consultantPairs.length + 1;

  const systemConsultant = {
    id: 'system',
    userEmail: 'System',
    totalDays: projectData.timesheets.reduce((sum, { totalDays }) => sum + totalDays, 0),
    days: aggregateConsultantData(projectData.timesheets),
  };

  // Format the filename for the document title - remove the year suffix
  const month = format(selectedDate, 'yyyyMM');
  const projectName = projectData.projectName.replace(/[^a-zA-Z0-9-_]/g, '_');
  const projectCode = projectData.projectCode || 'NO_CODE';
  const documentTitle = `${month}-BOMZAI-${projectName}-${projectCode}`;

  return (
    <Document title={documentTitle} author="BOMZAI" creator="BOMZAI" producer="BOMZAI">
      {/* Cover Page (Page 1) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <CoverPage projectData={projectData} period={{ start: periodStart, end: periodEnd }} />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`1/${totalPages}`}
        />
      </Page>

      {/* Summary Page (Page 2) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <View style={styles.summaryPage}>
          <View style={styles.summaryTable}>
            <ConsultantSummaryTable projectData={projectData} />
          </View>
        </View>
        <WeeklyCalendarView
          consultant={systemConsultant}
          period={{ start: periodStart, end: periodEnd }}
        />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`2/${totalPages}`}
        />
      </Page>

      {/* Consultant Pair Pages */}
      {consultantPairs.map((pair, pageIndex) => (
        <Page key={`pair-${pageIndex}`} size="A4" style={styles.page} wrap={false}>
          <View style={styles.consultantPairContainer}>
            {pair.map((timesheet, index) => (
              <React.Fragment key={timesheet.id}>
                <View style={[styles.consultantContainer]}>
                  <ConsultantTimesheet
                    consultant={timesheet}
                    period={{ start: periodStart, end: periodEnd }}
                    projectData={projectData}
                    showKPIs={false}
                  />
                </View>
                {index === 0 && pair.length > 1 && <View style={styles.consultantSeparator} />}
              </React.Fragment>
            ))}
          </View>
          <Footer
            projectName={projectData.projectName}
            projectCode={projectData.projectCode || '-'}
            selectedDate={selectedDate}
            pageNumber={`${pageIndex + 3}/${totalPages}`}
          />
        </Page>
      ))}

      {/* Signature Page (Last Page) */}
      <Page size="A4" style={styles.page} wrap={false}>
        <SignaturePage />
        <Footer
          projectName={projectData.projectName}
          projectCode={projectData.projectCode || '-'}
          selectedDate={selectedDate}
          pageNumber={`${totalPages}/${totalPages}`}
        />
      </Page>
    </Document>
  );
};

TimeSheetPDFTemplate.propTypes = {
  projectData: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    projectCode: PropTypes.string,
    contractRef: PropTypes.string,
    orderNumber: PropTypes.string,
    totalUO: PropTypes.number,
    totalTickets: PropTypes.number,
    totalDays: PropTypes.number.isRequired,
    timesheets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userEmail: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        totalDays: PropTypes.number.isRequired,
        days: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default TimeSheetPDFTemplate;
