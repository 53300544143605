import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Box,
  TablePagination,
  Tooltip,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';

const statusColors = {
  new: 'error',
  inProgress: 'warning',
  resolved: 'success',
  closed: 'default',
};

const BugReportList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Add filter states
  const [statusFilter, setStatusFilter] = useState(['new', 'inProgress', 'resolved']);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'bugReports'), orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, snapshot => {
      const bugsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBugs(bugsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewBug = bugId => {
    navigate(`/bug-reports/${bugId}`);
  };

  const filteredBugs = useMemo(() => {
    return bugs.filter(bug => {
      const matchesStatus = statusFilter.includes('all') || statusFilter.includes(bug.status);
      const matchesSearch =
        bug.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        bug.reportedBy.email.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  }, [bugs, statusFilter, searchQuery]);

  const handleStatusFilterChange = event => {
    const value = event.target.value;
    if (value.includes('all')) {
      // If 'all' is selected, clear other selections
      setStatusFilter(['all']);
    } else {
      // Remove 'all' if other options are selected
      setStatusFilter(value.filter(status => status !== 'all'));
    }
    setPage(0);
  };

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page when search changes
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('bugReport.list.title')}
        </Typography>

        {/* Add filters */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label={t('bugReport.list.filters.search')}
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={t('bugReport.list.filters.search_placeholder')}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel>{t('bugReport.list.filters.status')}</InputLabel>
                <Select
                  multiple
                  value={statusFilter}
                  label={t('bugReport.list.filters.status')}
                  onChange={handleStatusFilterChange}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={
                            value === 'all'
                              ? t('bugReport.list.filters.all_statuses')
                              : t(`bugReport.status.${value}`)
                          }
                          size="small"
                          color={value === 'all' ? 'default' : statusColors[value]}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 6.5,
                        width: 250,
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox checked={statusFilter.includes('all')} />
                    <ListItemText primary={t('bugReport.list.filters.all_statuses')} />
                  </MenuItem>
                  {Object.keys(statusColors).map(status => (
                    <MenuItem key={status} value={status}>
                      <Checkbox checked={statusFilter.includes(status)} />
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Chip
                              label={t(`bugReport.status.${status}`)}
                              color={statusColors[status]}
                              size="small"
                              sx={{ minWidth: 100 }}
                            />
                          </Box>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('bugReport.list.columns.title')}</TableCell>
                <TableCell>{t('bugReport.list.columns.reportedBy')}</TableCell>
                <TableCell>{t('bugReport.list.columns.status')}</TableCell>
                <TableCell>{t('bugReport.list.columns.createdAt')}</TableCell>
                <TableCell align="right">{t('common.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBugs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(bug => (
                <TableRow key={bug.id} hover>
                  <TableCell>{bug.title}</TableCell>
                  <TableCell>{bug.reportedBy.email}</TableCell>
                  <TableCell>
                    <Chip
                      label={t(`bugReport.status.${bug.status}`)}
                      color={statusColors[bug.status]}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{format(bug.createdAt?.toDate(), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell align="right">
                    <Tooltip title={t('bugReport.list.view')}>
                      <IconButton size="small" onClick={() => handleViewBug(bug.id)}>
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredBugs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default BugReportList;
