import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import CoachAssignmentDialogView from './CoachAssignmentDialogView';

const CoachAssignmentDialogContainer = ({
  open = false,
  onClose = () => {},
  onSave = () => {},
  userEmail = '',
  initialCoaches = [],
}) => {
  // State management - must be at top level
  const [availableCoaches, setAvailableCoaches] = useState([]);
  const [formData, setFormData] = useState({
    coaches: initialCoaches.map(email => ({ email })),
  });

  // Validation effect
  useEffect(() => {
    if (!userEmail && open) {
      console.warn('CoachAssignmentDialogContainer: userEmail is required when dialog is open');
      onClose();
    }
  }, [userEmail, open, onClose]);

  // Load available coaches when dialog opens
  useEffect(() => {
    const loadCoaches = async () => {
      if (!open || !userEmail) return;

      try {
        const usersQuery = query(collection(db, 'users'), where('isActive', '!=', false));
        const snapshot = await getDocs(usersQuery);
        const coachEmails = snapshot.docs
          .map(doc => ({
            email: doc.data().email,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
          }))
          .filter(coach => coach.email !== userEmail); // Exclude the current user

        // Sort coaches by name if available, otherwise by email
        const sortedCoaches = coachEmails.sort((a, b) => {
          const nameA = a.firstName && a.lastName ? `${a.firstName} ${a.lastName}` : a.email;
          const nameB = b.firstName && b.lastName ? `${b.firstName} ${b.lastName}` : b.email;
          return nameA.localeCompare(nameB);
        });

        setAvailableCoaches(sortedCoaches);
      } catch (error) {
        console.error('Error loading coaches:', error);
      }
    };

    loadCoaches();
  }, [open, userEmail]);

  // Reset form when dialog opens/closes
  useEffect(() => {
    if (open) {
      setFormData({
        coaches: initialCoaches.map(email => ({ email })),
      });
    }
  }, [open, initialCoaches]);

  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = e => {
    e?.preventDefault();
    onSave({
      coaches: formData.coaches.map(coach => (typeof coach === 'string' ? coach : coach.email)),
      updatedAt: new Date(),
    });
  };

  // Early return if validation fails
  if (!userEmail && open) {
    return null;
  }

  return (
    <CoachAssignmentDialogView
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      formData={formData}
      onFormChange={handleFormChange}
      availableCoaches={availableCoaches}
      userEmail={userEmail}
    />
  );
};

CoachAssignmentDialogContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  userEmail: PropTypes.string.isRequired,
  initialCoaches: PropTypes.arrayOf(PropTypes.string),
};

export default CoachAssignmentDialogContainer;
