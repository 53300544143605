/**
 * @component DashboardContainer
 * @description Container component for the dashboard page.
 * Manages data fetching and state for user and project analytics.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import DashboardView from './DashboardView';
import { aggregateProjectData } from '../../utils/dataProcessing';

const DashboardContainer = () => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Initialize state from URL params
  const initialYear = parseInt(searchParams.get('year')) || new Date().getFullYear();
  const initialMode = searchParams.get('mode') || 'monthly';
  const initialViewType = searchParams.get('view') || 'users';
  const initialIsLifetime = searchParams.get('lifetime') === 'true';

  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState(initialMode);
  const [selectedDate, setSelectedDate] = useState(new Date(initialYear, 0));
  const [viewType, setViewType] = useState(initialViewType);
  const [kpiData, setKpiData] = useState(null);
  const [lifetimeKpiData, setLifetimeKpiData] = useState(null);
  const [isLifetime, setIsLifetime] = useState(initialIsLifetime);

  // Update URL when parameters change
  const updateUrlParams = useCallback(
    params => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          newSearchParams.set(key, value.toString());
        } else {
          newSearchParams.delete(key);
        }
      });
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    },
    [navigate, searchParams]
  );

  // Wrap state setters to update URL
  const handleViewModeChange = useCallback(
    mode => {
      setViewMode(mode);
      updateUrlParams({ mode });
    },
    [updateUrlParams]
  );

  const handleDateChange = useCallback(
    date => {
      setSelectedDate(date);
      updateUrlParams({ year: date.getFullYear() });
    },
    [updateUrlParams]
  );

  const handleViewTypeChange = useCallback(
    type => {
      setViewType(type);
      updateUrlParams({ view: type });
    },
    [updateUrlParams]
  );

  const handleLifetimeToggle = useCallback(
    value => {
      setIsLifetime(value);
      updateUrlParams({ lifetime: value });
    },
    [updateUrlParams]
  );

  const processKPIData = (data, year) => {
    if (!data) return null;

    // Sort users alphabetically by email
    if (data.users) {
      const sortedUsers = {};
      Object.entries(data.users)
        .sort(([emailA], [emailB]) => emailA.localeCompare(emailB))
        .forEach(([email, userData]) => {
          sortedUsers[email] = userData;
        });
      data.users = sortedUsers;
    }

    // Sort projects alphabetically by name
    if (data.projects) {
      const aggregatedProjects = aggregateProjectData(data.projects, {
        includeYearlyData: true,
        year,
      });

      const sortedProjects = {};
      Object.entries(aggregatedProjects)
        .sort(([, projectA], [, projectB]) => projectA.name.localeCompare(projectB.name))
        .forEach(([id, projectData]) => {
          sortedProjects[id] = projectData;
        });
      data.projects = sortedProjects;
    }

    return data;
  };

  const fetchKPIData = useCallback(async () => {
    try {
      setLoading(true);
      const year = selectedDate.getFullYear().toString();
      const kpiRef = doc(db, 'kpis', year);
      const lifetimeRef = doc(db, 'kpis_lifetime', 'global');

      const [kpiSnap, lifetimeKpiSnap] = await Promise.all([getDoc(kpiRef), getDoc(lifetimeRef)]);

      if (kpiSnap.exists()) {
        const data = kpiSnap.data();
        const processedData = processKPIData(data, year);
        setKpiData(processedData);
      } else {
        setKpiData(null);
        showToast(t('dashboard.no_data_for_year', { year }), 'info');
      }

      if (lifetimeKpiSnap.exists()) {
        const lifetimeData = lifetimeKpiSnap.data();
        const processedLifetimeData = processKPIData(lifetimeData, 'all');
        setLifetimeKpiData(processedLifetimeData);
      }
    } catch (error) {
      showToast(t('dashboard.error.loading'), 'error');
    } finally {
      setLoading(false);
    }
  }, [selectedDate, t, showToast]);

  useEffect(() => {
    fetchKPIData();
  }, [fetchKPIData]);

  return (
    <DashboardView
      loading={loading}
      kpiData={kpiData}
      lifetimeKpiData={lifetimeKpiData}
      viewMode={viewMode}
      viewType={viewType}
      selectedDate={selectedDate}
      isLifetime={isLifetime}
      onViewModeChange={handleViewModeChange}
      onViewTypeChange={handleViewTypeChange}
      onDateChange={handleDateChange}
      onLifetimeToggle={handleLifetimeToggle}
    />
  );
};

export default DashboardContainer;
