import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box,
  Divider,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { filterAuthorizedMissions } from '../../utils/missionUtils';

const UserMissionsDialog = ({
  open = false,
  onClose = () => {},
  projects = [],
  userEmail = '',
  isSuperAdmin = false,
}) => {
  const { t } = useTranslation();

  console.log('UserMissionsDialog - Input:', {
    userEmail,
    isSuperAdmin,
    projectsCount: projects.length,
    projects: projects.map(p => ({
      id: p.id,
      name: p.name,
      missionCount: p.missions.length,
      missions: p.missions.map(m => ({
        id: m.id,
        name: m.name,
        authorizedUsers: m.authorizedUsers,
      })),
    })),
  });

  const authorizedProjects = projects
    .map(project => ({
      ...project,
      missions: filterAuthorizedMissions(project.missions, userEmail, isSuperAdmin),
    }))
    .filter(project => project.missions.length > 0);

  console.log('UserMissionsDialog - Final authorized projects:', {
    authorizedProjectsCount: authorizedProjects.length,
    authorizedProjects: authorizedProjects.map(p => ({
      id: p.id,
      name: p.name,
      missionCount: p.missions.length,
    })),
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {t('management.users.missions_dialog.title', { email: userEmail })}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {authorizedProjects.length === 0 ? (
          <Typography color="text.secondary" sx={{ py: 2 }}>
            {t('management.users.missions_dialog.no_missions')}
          </Typography>
        ) : (
          authorizedProjects.map((project, index) => (
            <React.Fragment key={project.id}>
              {index > 0 && <Divider sx={{ my: 2 }} />}
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: project.color || 'inherit',
                  }}
                >
                  {project.name} ({project.code})
                </Typography>
                <List dense>
                  {project.missions.map(mission => (
                    <ListItem key={mission.id}>
                      <ListItemText
                        primary={mission.name}
                        secondary={
                          <>
                            {isSuperAdmin && (
                              <Typography variant="body2" component="span">
                                {t('management.users.missions_dialog.daily_rate', {
                                  rate: mission.dailyRate,
                                })}
                              </Typography>
                            )}
                            {mission.description && (
                              <Typography variant="body2" color="text.secondary">
                                {mission.description}
                              </Typography>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </React.Fragment>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

UserMissionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      color: PropTypes.string,
      missions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          dailyRate: PropTypes.number.isRequired,
          description: PropTypes.string,
          authorizedUsers: PropTypes.arrayOf(PropTypes.string),
        })
      ).isRequired,
    })
  ).isRequired,
  userEmail: PropTypes.string.isRequired,
  isSuperAdmin: PropTypes.bool,
};

export default UserMissionsDialog;
