/**
 * @component SidebarContainer
 * @description Container component for the sidebar navigation.
 * Manages sidebar state, user authentication, theme, and navigation.
 */
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useToast } from '../../contexts/toast';
import { useThemeMode } from '../../contexts/theme';
import SidebarView from './SidebarView';
import { useTranslation } from 'react-i18next';
import {
  AccessTime as TimeSheetIcon,
  RateReview as ReviewIcon,
  Business as ManagementIcon,
  CalendarToday as CalendarIcon,
  People as UsersIcon,
  History as HistoryIcon,
  Storage as StorageIcon,
  Print as PrintIcon,
  Dashboard as DashboardIcon,
  BugReport as BugReportIcon,
  ListAlt as ListAltIcon,
} from '@mui/icons-material';
import { useCoachees } from '../../hooks/useCoachees';

const SidebarContainer = () => {
  // Context hooks
  const { user, logout } = useAuth();
  const { showToast } = useToast();
  const { mode, toggleTheme } = useThemeMode();
  const { i18n } = useTranslation();

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Local state
  const [anchorEl, setAnchorEl] = useState(null); // User menu anchor
  const [open, setOpen] = useState(true); // Sidebar expanded state
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  // Add this hook after other hooks
  const coachees = useCoachees(user?.email);

  /**
   * Handles opening the user menu
   * @param {Event} event - Click event
   */
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the user menu
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles user logout
   * Navigates to login page on success
   * @async
   */
  const handleLogout = async () => {
    try {
      await logout();
      showToast('Successfully logged out', 'success');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      showToast('Error during logout', 'error');
    }
    handleMenuClose();
  };

  /**
   * Handles navigation to different routes
   * @param {string} path - Target route path
   */
  const handleNavigate = path => {
    navigate(path);
  };

  /**
   * Toggles sidebar expanded/collapsed state
   */
  const handleToggle = () => {
    setOpen(!open);
  };

  /**
   * Handles theme toggle
   * Delegates to theme context
   */
  const handleThemeToggle = () => {
    toggleTheme();
  };

  const handleLanguageClick = event => {
    event.stopPropagation();
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageSelect = language => {
    try {
      i18n.changeLanguage(language);
      localStorage.setItem('preferredLanguage', language);
      showToast(`Language changed to ${language.toUpperCase()}`, 'success');
    } catch (error) {
      console.error('Error changing language:', error);
      showToast('Failed to change language', 'error');
    }
    handleLanguageClose();
  };

  /**
   * Gets navigation items based on user role and coach status
   * @returns {Object} Navigation items configuration
   */
  const getNavigationItems = () => {
    const isCoach = coachees && coachees.length > 0;
    console.log('coachees isCoach', coachees, isCoach);

    return {
      user: {
        title: 'navigation.sections.user',
        items: [
          {
            path: '/timesheet',
            icon: <TimeSheetIcon />,
            label: 'navigation.items.timesheet',
            roles: ['user', 'rh', 'superadmin'],
            'data-testid': 'nav-/timesheet',
          },
        ],
      },
      rh: {
        title: 'navigation.sections.rh',
        items: [
          {
            path: '/timesheet/review',
            icon: <ReviewIcon />,
            label: 'navigation.items.review_timesheets',
            roles: ['user', 'rh', 'superadmin'],
            show: isCoach || user?.role === 'rh' || user?.role === 'superadmin',
            'data-testid': 'nav-/timesheet/review',
          },
          {
            path: '/timesheet/print',
            icon: <PrintIcon />,
            label: 'navigation.items.timesheet_print',
            roles: ['rh', 'superadmin'],
          },
          {
            path: '/admin/calendar',
            icon: <CalendarIcon />,
            label: 'navigation.items.calendar',
            roles: ['rh', 'superadmin'],
          },
          {
            path: '/admin/users',
            icon: <UsersIcon />,
            label: 'navigation.items.user_management',
            roles: ['rh', 'superadmin'],
          },
        ],
      },
      business: {
        title: 'navigation.sections.business',
        items: [
          {
            path: '/management',
            icon: <ManagementIcon />,
            label: 'navigation.items.management',
            roles: ['rh', 'superadmin'],
          },
        ],
      },
      superadmin: {
        title: 'navigation.sections.superadmin',
        items: [
          {
            path: '/dashboard',
            icon: <DashboardIcon />,
            label: 'navigation.items.dashboard',
            roles: ['rh', 'superadmin'],
          },
          {
            path: '/audit',
            icon: <HistoryIcon />,
            label: 'navigation.items.audit_trails',
            roles: ['superadmin'],
          },
          {
            path: '/backup',
            icon: <StorageIcon />,
            label: 'navigation.items.database_tools',
            roles: ['superadmin'],
          },
        ],
      },
      support: {
        title: 'navigation.sections.support',
        items: [
          {
            icon: <BugReportIcon />,
            label: 'navigation.items.report_bug',
            path: '/bug-report',
            roles: ['user', 'rh', 'superadmin'],
          },
          {
            icon: <ListAltIcon />,
            label: 'navigation.items.bug_list',
            path: '/bug-reports',
            roles: ['superadmin'],
          },
        ],
      },
    };
  };

  return (
    <>
      <SidebarView
        user={user}
        mode={mode}
        currentPath={location.pathname}
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        open={open}
        navigationItems={getNavigationItems()}
        onToggle={handleToggle}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onLogout={handleLogout}
        onNavigate={handleNavigate}
        onThemeToggle={handleThemeToggle}
        onLanguageClick={handleLanguageClick}
        languageAnchorEl={languageAnchorEl}
        onLanguageClose={handleLanguageClose}
        onLanguageSelect={handleLanguageSelect}
      />
    </>
  );
};

export default SidebarContainer;
