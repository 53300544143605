/**
 * @component ProjectDialogContainer
 * @description Container component for project creation/editing dialog.
 * Manages form state, date handling, and mission management.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { collection, addDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { format, isValid, parseISO } from 'date-fns';
import { db } from '../../firebase/config';
import { useToast } from '../../contexts/toast';
import ProjectDialogView from './ProjectDialogView';
import MissionDialogContainer from './MissionDialogContainer';
import { useAuth } from '../../contexts/auth';

/**
 * @typedef {Object} ProjectDialogContainerProps
 * @property {boolean} open - Dialog visibility state
 * @property {Function} onClose - Dialog close handler
 * @property {Object} project - Project data for editing (null for creation)
 */
const ProjectDialogContainer = ({ open = false, onClose = () => {}, project = null }) => {
  const { showToast } = useToast();
  const { user } = useAuth();

  // Form state management
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    contractNumber: '',
    purchaseOrder: '',
    totalBudget: 0,
    startDate: '',
    status: 'ACTIVE',
    missions: [],
    invoicable: true,
    forfait: false,
  });
  const [openMissionDialog, setOpenMissionDialog] = useState(false);
  const [selectedMissionIndex, setSelectedMissionIndex] = useState(null);

  /**
   * Initializes form data when editing existing project
   * Handles various date formats from Firestore
   */
  useEffect(() => {
    if (project) {
      try {
        let formattedDate = '';

        // Handle different date formats
        if (project.startDate) {
          if (project.startDate instanceof Timestamp) {
            const date = project.startDate.toDate();
            formattedDate = isValid(date) ? format(date, 'yyyy-MM-dd') : '';
          } else if (project.startDate instanceof Date) {
            formattedDate = isValid(project.startDate)
              ? format(project.startDate, 'yyyy-MM-dd')
              : '';
          } else if (typeof project.startDate === 'string') {
            const parsedDate = parseISO(project.startDate);
            formattedDate = isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '';
          }
        }

        setFormData({
          code: project.code || '',
          name: project.name || '',
          contractNumber: project.contractNumber || '',
          purchaseOrder: project.purchaseOrder || '',
          totalBudget: project.totalBudget || 0,
          startDate: formattedDate,
          status: project.status || 'ACTIVE',
          missions: project.missions || [],
          invoicable: project.invoicable !== undefined ? project.invoicable : true,
          forfait: project.forfait !== undefined ? project.forfait : false,
        });
      } catch (error) {
        console.error('Error formatting date:', error);
        console.error('Error details:', {
          dateValue: project.startDate,
          dateType: typeof project.startDate,
          dateProperties: project.startDate ? Object.keys(project.startDate) : null,
        });
        showToast('Error loading project date', 'error');

        // Fallback form data without date
        setFormData({
          code: project.code || '',
          name: project.name || '',
          contractNumber: project.contractNumber || '',
          purchaseOrder: project.purchaseOrder || '',
          totalBudget: project.totalBudget || 0,
          startDate: '',
          status: project.status || 'ACTIVE',
          missions: project.missions || [],
          invoicable: project.invoicable !== undefined ? project.invoicable : true,
          forfait: project.forfait !== undefined ? project.forfait : false,
        });
      }
    } else {
      // Reset form for new project
      setFormData({
        code: '',
        name: '',
        contractNumber: '',
        purchaseOrder: '',
        totalBudget: 0,
        startDate: '',
        status: 'ACTIVE',
        missions: [],
        invoicable: true,
        forfait: false,
      });
    }
  }, [project, showToast]);

  /**
   * Updates form field value
   * @param {string} field - Field name to update
   * @param {any} value - New field value
   */
  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Handles form submission
   * Creates or updates project in Firestore
   * @param {Event} e - Form submission event
   */
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const projectData = {
        ...formData,
        updatedAt: new Date(),
        performedBy: user.email,
      };

      if (project) {
        const projectRef = doc(db, 'projects', project.id);
        await updateDoc(projectRef, {
          ...projectData,
          performedBy: user.email,
          updatedAt: new Date(),
        });
        showToast('Project updated successfully', 'success');
      } else {
        projectData.createdAt = new Date();
        projectData.performedBy = user.email;
        await addDoc(collection(db, 'projects'), projectData);
        showToast('Project created successfully', 'success');
      }
      onClose();
    } catch (error) {
      console.error('Error saving project:', error);
      showToast('Error saving project', 'error');
    }
  };

  /**
   * Mission management handlers
   */
  const handleAddMission = () => {
    setSelectedMissionIndex(null);
    setOpenMissionDialog(true);
  };

  const handleEditMission = index => {
    setSelectedMissionIndex(index);
    setOpenMissionDialog(true);
  };

  const handleDeleteMission = index => {
    setFormData(prev => ({
      ...prev,
      missions: prev.missions.filter((_, i) => i !== index),
    }));
  };

  const handleMissionSave = missionData => {
    setFormData(prev => {
      const newMissions = [...prev.missions];
      if (selectedMissionIndex !== null) {
        newMissions[selectedMissionIndex] = missionData;
      } else {
        newMissions.push(missionData);
      }
      return {
        ...prev,
        missions: newMissions,
      };
    });
    setOpenMissionDialog(false);
  };

  return (
    <>
      <ProjectDialogView
        open={open}
        onClose={onClose}
        project={project}
        formData={formData}
        onFormChange={handleFormChange}
        onSubmit={handleSubmit}
        onAddMission={handleAddMission}
        onEditMission={handleEditMission}
        onDeleteMission={handleDeleteMission}
        missions={formData.missions}
        isEdit={!!project}
      />
      <MissionDialogContainer
        open={openMissionDialog}
        onClose={() => setOpenMissionDialog(false)}
        onSave={handleMissionSave}
        mission={selectedMissionIndex !== null ? formData.missions[selectedMissionIndex] : null}
      />
    </>
  );
};

// PropTypes validation
ProjectDialogContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  project: PropTypes.object,
};

export default ProjectDialogContainer;
