import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Chip,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useToast } from '../contexts/toast';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../contexts/auth';

const statusColors = {
  new: 'error',
  inProgress: 'warning',
  resolved: 'success',
  closed: 'default',
};

const BugReportDetail = () => {
  const { bugId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { user } = useAuth();
  const [bug, setBug] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [submittingComment, setSubmittingComment] = useState(false);

  useEffect(() => {
    const fetchBug = async () => {
      try {
        const bugDoc = await getDoc(doc(db, 'bugReports', bugId));
        if (bugDoc.exists()) {
          setBug({ id: bugDoc.id, ...bugDoc.data() });
        }
      } catch (error) {
        console.error('Error fetching bug report:', error);
        showToast(t('bugReport.detail.error.loading'), 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchBug();
  }, [bugId, t, showToast]);

  const handleStatusChange = async newStatus => {
    setUpdating(true);
    try {
      await updateDoc(doc(db, 'bugReports', bugId), {
        status: newStatus,
        updatedAt: new Date(),
      });
      showToast(t('bugReport.detail.status_updated'), 'success');
      setBug(prev => ({ ...prev, status: newStatus }));
    } catch (error) {
      console.error('Error updating status:', error);
      showToast(t('bugReport.detail.error.update_status'), 'error');
    } finally {
      setUpdating(false);
    }
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;

    setSubmittingComment(true);
    try {
      await updateDoc(doc(db, 'bugReports', bugId), {
        comments: arrayUnion({
          text: newComment.trim(),
          createdAt: new Date(),
          author: {
            email: user?.email || 'anonymous',
            role: user?.role || 'anonymous',
          },
        }),
      });
      setNewComment('');
      showToast(t('bugReport.detail.comments.added'), 'success');
    } catch (error) {
      console.error('Error adding comment:', error);
      showToast(t('bugReport.messages.add_comment'), 'error');
    } finally {
      setSubmittingComment(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!bug) {
    return (
      <Container>
        <Typography variant="h6" color="error">
          {t('bugReport.detail.not_found')}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button startIcon={<ArrowBackIcon />} onClick={() => navigate('/bug-reports')}>
            {t('common.back')}
          </Button>
          <Typography variant="h4">{bug.title}</Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                {t('bugReport.fields.description')}
              </Typography>
              <Typography paragraph>{bug.description}</Typography>

              <Typography variant="h6" gutterBottom>
                {t('bugReport.fields.steps')}
              </Typography>
              <Typography paragraph>{bug.steps}</Typography>

              <Typography variant="h6" gutterBottom>
                {t('bugReport.fields.expected_behavior')}
              </Typography>
              <Typography paragraph>{bug.expectedBehavior}</Typography>

              <Typography variant="h6" gutterBottom>
                {t('bugReport.fields.actual_behavior')}
              </Typography>
              <Typography paragraph>{bug.actualBehavior}</Typography>

              {bug.screenshotUrl && (
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('bugReport.fields.screenshot')}
                  </Typography>
                  <img
                    src={bug.screenshotUrl}
                    alt="Bug screenshot"
                    style={{ maxWidth: '100%', borderRadius: 8 }}
                  />
                </Box>
              )}
            </Paper>

            <Paper sx={{ p: 3, mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                {t('bugReport.detail.comments.title')}
              </Typography>

              <List>
                {bug.comments?.map((comment, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <Divider variant="inset" component="li" />}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          <CommentIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2">{comment.author.email}</Typography>
                            <Typography variant="caption" color="text.secondary">
                              {format(comment.createdAt.toDate(), 'dd/MM/yyyy HH:mm')}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {comment.text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
                {!bug.comments?.length && (
                  <Typography color="text.secondary" sx={{ py: 2, textAlign: 'center' }}>
                    {t('bugReport.detail.comments.no_comments')}
                  </Typography>
                )}
              </List>

              <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={newComment}
                  onChange={e => setNewComment(e.target.value)}
                  placeholder={t('bugReport.detail.comments.placeholder')}
                  disabled={submittingComment}
                />
                <Button
                  variant="contained"
                  onClick={handleCommentSubmit}
                  disabled={!newComment.trim() || submittingComment}
                  sx={{ minWidth: 100 }}
                  endIcon={<SendIcon />}
                >
                  {submittingComment ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('bugReport.detail.comments.submit')
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {t('bugReport.detail.info')}
              </Typography>

              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>{t('bugReport.list.columns.status')}</InputLabel>
                  <Select
                    value={bug.status}
                    label={t('bugReport.list.columns.status')}
                    onChange={e => handleStatusChange(e.target.value)}
                    disabled={updating}
                  >
                    {Object.keys(statusColors).map(status => (
                      <MenuItem key={status} value={status}>
                        <Chip
                          label={t(`bugReport.status.${status}`)}
                          color={statusColors[status]}
                          size="small"
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography variant="body2" color="text.secondary" paragraph>
                <strong>{t('bugReport.list.columns.reportedBy')}:</strong> {bug.reportedBy.email}
              </Typography>

              <Typography variant="body2" color="text.secondary" paragraph>
                <strong>{t('bugReport.list.columns.createdAt')}:</strong>{' '}
                {format(bug.createdAt.toDate(), 'dd/MM/yyyy HH:mm')}
              </Typography>

              {bug.browserInfo && (
                <>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    <strong>{t('bugReport.detail.browser')}:</strong> {bug.browserInfo.userAgent}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    <strong>{t('bugReport.detail.url')}:</strong> {bug.browserInfo.url}
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default BugReportDetail;
