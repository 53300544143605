import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Chip,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  TablePagination,
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  startAfter,
  getCountFromServer,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { format } from 'date-fns';
import AuditTrailFilters from './AuditTrailFilters';

const AuditTrailViewer = ({
  entityType,
  entityId,
  hideFilters = false,
  hideAffectedColumn = false,
  hideEntityColumn = false,
  isEmpty = false,
}) => {
  // State management
  const [auditTrails, setAuditTrails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntities, setSelectedEntities] = useState(['USER', 'TIMESHEET', 'PROJECT']);
  const [selectedActions, setSelectedActions] = useState(['CREATE', 'UPDATE', 'DELETE']);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);

  // Refs to track changes
  const currentEntityId = useRef(entityId);
  const isInitialMount = useRef(true);
  const lastDocRef = useRef(null);

  // Update ref when lastDoc changes
  useEffect(() => {
    lastDocRef.current = lastDoc;
  }, [lastDoc]);

  // Utility function for timestamp conversion
  const safeTimestampToDate = useCallback(timestamp => {
    if (!timestamp) return null;
    if (typeof timestamp?.toDate === 'function') return timestamp.toDate();
    if (timestamp?.seconds) {
      return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000);
    }
    if (timestamp instanceof Date) return timestamp;
    return null;
  }, []);

  // Query builder
  const buildQuery = useCallback(() => {
    const auditRef = collection(db, 'audit_trails');
    let q = query(auditRef);

    if (entityType && entityId) {
      const conditions = [
        where('entityType', '==', entityType),
        where('entityId', '==', entityId),
        orderBy('timestamp', 'desc'),
      ];

      if (page > 0 && lastDocRef.current) {
        conditions.push(startAfter(lastDocRef.current));
      }

      conditions.push(limit(rowsPerPage));
      q = query(q, ...conditions);
      return q;
    }

    // General audit trail view filters
    if (selectedEntities.length > 0 && selectedEntities.length < 3) {
      q = query(q, where('entityType', 'in', selectedEntities));
    }

    if (selectedActions.length > 0 && selectedActions.length < 3) {
      q = query(q, where('action', 'in', selectedActions));
    }

    q = query(q, orderBy('timestamp', 'desc'), limit(rowsPerPage));
    return q;
  }, [entityType, entityId, page, rowsPerPage, selectedEntities, selectedActions]);

  // Data loading function
  const loadAuditTrails = useCallback(async () => {
    if (isEmpty) {
      setAuditTrails([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const q = buildQuery();
      const snapshot = await getDocs(q);

      const trails = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: safeTimestampToDate(doc.data().timestamp),
      }));

      setAuditTrails(trails);
      if (snapshot.docs.length > 0) {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error('Error loading audit trails:', error);
      setError('Failed to load audit trails');
    } finally {
      setLoading(false);
    }
  }, [buildQuery, isEmpty, safeTimestampToDate]);

  // Effect to handle entity changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (currentEntityId.current !== entityId) {
      currentEntityId.current = entityId;
      setPage(0);
      setLastDoc(null);
      loadAuditTrails();
    }
  }, [entityId, loadAuditTrails]);

  // Effect to handle pagination changes
  useEffect(() => {
    if (!isInitialMount.current) {
      loadAuditTrails();
    }
  }, [page, rowsPerPage, loadAuditTrails]);

  // Effect to get total count
  useEffect(() => {
    const getTotalCount = async () => {
      try {
        const countQuery =
          entityType && entityId
            ? query(
                collection(db, 'audit_trails'),
                where('entityType', '==', entityType),
                where('entityId', '==', entityId)
              )
            : query(collection(db, 'audit_trails'));

        const countSnapshot = await getCountFromServer(countQuery);
        setTotalCount(countSnapshot.data().count);
      } catch (error) {
        console.error('Error getting total count:', error);
      }
    };

    getTotalCount();
  }, [entityType, entityId]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setLastDoc(null);
  };

  const handleEntityChange = event => {
    const newValue = event.target.value;
    if (newValue && newValue.length > 0) {
      setSelectedEntities(newValue);
    }
  };

  const handleActionChange = event => {
    const newValue = event.target.value;
    if (newValue && newValue.length > 0) {
      setSelectedActions(newValue);
    }
  };

  const toggleRow = id => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const renderUser = trail => {
    const performedBy = trail.performedBy || 'system';
    return (
      <Chip
        icon={performedBy === 'system' ? <AdminPanelSettingsIcon /> : <PersonIcon />}
        label={performedBy}
        variant="outlined"
        color={performedBy === 'system' ? 'secondary' : 'primary'}
        size="small"
      />
    );
  };

  const renderAffectedUser = trail => {
    if (!trail.affectedEntity) {
      return (
        <Chip
          icon={<PersonIcon />}
          label="Unknown"
          variant="outlined"
          color="default"
          size="small"
        />
      );
    }

    const { id = 'unknown', name = 'Unknown' } = trail.affectedEntity;
    return (
      <Tooltip title={`ID: ${id}`}>
        <Chip icon={<PersonIcon />} label={name} variant="outlined" color="info" size="small" />
      </Tooltip>
    );
  };

  const renderChanges = changes => {
    if (!changes) return null;

    if (changes.timeEntries) {
      return (
        <Box>
          <Typography variant="subtitle2">Time Entries Updated</Typography>
          <Typography variant="body2" color="textSecondary">
            Multiple days modified
          </Typography>
        </Box>
      );
    }

    if (changes.role) {
      return (
        <Box>
          <Typography variant="subtitle2">Role Changed</Typography>
          <Typography variant="body2">
            From: {changes.role.from || 'none'} → To: {changes.role.to}
          </Typography>
        </Box>
      );
    }

    const changedFields = Object.keys(changes);
    return (
      <Box>
        <Typography variant="subtitle2">Modified Fields: {changedFields.length}</Typography>
        <Typography variant="body2" color="textSecondary">
          {changedFields.join(', ')}
        </Typography>
      </Box>
    );
  };

  const getActionIcon = action => {
    switch (action) {
      case 'CREATE':
        return <AddIcon fontSize="small" />;
      case 'UPDATE':
        return <EditIcon fontSize="small" />;
      case 'DELETE':
        return <DeleteIcon fontSize="small" />;
      default:
        return <EditIcon fontSize="small" />;
    }
  };

  const getActionColor = action => {
    switch (action) {
      case 'CREATE':
        return 'success';
      case 'UPDATE':
        return 'primary';
      case 'DELETE':
        return 'error';
      default:
        return 'default';
    }
  };

  const renderEntity = type => (
    <Chip icon={<FolderIcon />} label={type} size="small" color="default" variant="outlined" />
  );

  const renderAction = action => (
    <Chip
      icon={getActionIcon(action)}
      label={action}
      size="small"
      color={getActionColor(action)}
      variant="outlined"
    />
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ p: 3 }}>
        {error}
      </Typography>
    );
  }

  if (!auditTrails.length) {
    return (
      <>
        {!hideFilters && (
          <AuditTrailFilters
            selectedEntities={selectedEntities}
            selectedActions={selectedActions}
            onEntityChange={handleEntityChange}
            onActionChange={handleActionChange}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            gap: 2,
            backgroundColor: 'background.paper',
            borderRadius: 1,
            mt: 2,
          }}
        >
          <SearchOffIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
          <Typography variant="h6" color="text.secondary">
            {isEmpty ? 'No Audit Trail Available' : 'No Audit Trails Found'}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {isEmpty
              ? 'Audit trail will be available after saving the timesheet'
              : entityType
                ? `No audit trails found for this ${entityType.toLowerCase()}`
                : selectedEntities.length < 3 || selectedActions.length < 3
                  ? 'Try adjusting your filters to see more results'
                  : 'No audit trails available for the selected criteria'}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {!hideFilters && (
        <AuditTrailFilters
          selectedEntities={selectedEntities}
          selectedActions={selectedActions}
          onEntityChange={handleEntityChange}
          onActionChange={handleActionChange}
        />
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Timestamp</TableCell>
              {!hideEntityColumn && <TableCell>Entity</TableCell>}
              <TableCell>Action</TableCell>
              {!hideAffectedColumn && <TableCell>Affected</TableCell>}
              <TableCell>Performed By</TableCell>
              <TableCell>Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditTrails.map(trail => (
              <React.Fragment key={trail.id}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <IconButton
                      size="small"
                      onClick={() => toggleRow(trail.id)}
                      aria-label="expand row"
                    >
                      {expandedRows.has(trail.id) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {trail.timestamp ? format(trail.timestamp, 'yyyy-MM-dd HH:mm:ss') : 'N/A'}
                  </TableCell>
                  {!hideEntityColumn && <TableCell>{renderEntity(trail.entityType)}</TableCell>}
                  <TableCell>{renderAction(trail.action)}</TableCell>
                  {!hideAffectedColumn && <TableCell>{renderAffectedUser(trail)}</TableCell>}
                  <TableCell>{renderUser(trail)}</TableCell>
                  <TableCell>{renderChanges(trail.changes)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={expandedRows.has(trail.id)} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Detailed Changes
                        </Typography>
                        <pre
                          style={{
                            whiteSpace: 'pre-wrap',
                            backgroundColor: '#f5f5f5',
                            padding: 2,
                            borderRadius: 1,
                            maxHeight: '300px',
                            overflow: 'auto',
                          }}
                        >
                          {JSON.stringify(trail.changes, null, 2)}
                        </pre>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </TableContainer>
    </>
  );
};

AuditTrailViewer.propTypes = {
  entityType: PropTypes.string,
  entityId: PropTypes.string,
  hideFilters: PropTypes.bool,
  hideAffectedColumn: PropTypes.bool,
  hideEntityColumn: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default AuditTrailViewer;
