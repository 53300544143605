import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Hook to get list of users where the connected user is a coach
 * @param {string} coachEmail - Email of the connected user
 * @returns {Object} List of users where the connected user is a coach, loading state, and error
 */
export const useCoachees = coachEmail => {
  const [coachees, setCoachees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoachees = async () => {
      try {
        if (!coachEmail) {
          setCoachees([]);
          setLoading(false);
          return;
        }

        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('coaches', 'array-contains', coachEmail));
        const querySnapshot = await getDocs(q);
        const fetchedCoachees = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCoachees(fetchedCoachees);
        setError(null);
      } catch (err) {
        console.error('Error fetching coachees:', err);
        setError(err);
        setCoachees([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCoachees();
  }, [coachEmail]);

  return { coachees, loading, error };
};
