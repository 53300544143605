/**
 * @component TimeSheetReviewView
 * @description Presentation component for reviewing timesheets.
 * Displays a list of timesheets and provides approval/rejection actions.
 */

/**
 * @typedef {Object} TimeSheetReviewViewProps
 * @property {Array} timesheets - List of timesheets to review
 * @property {Function} onViewTimesheet - Handler for viewing timesheet details
 * @property {Function} onApprove - Handler for approving timesheet
 * @property {Function} onReject - Handler for rejecting timesheet
 * @property {String} userRole - User role
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Stack,
  Switch,
  FormControlLabel,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Tooltip,
  Chip,
  Badge,
  Grid,
} from '@mui/material';
import { formatMonthYear } from '../../utils/dateFormatters';
import { useTranslation } from 'react-i18next';
import {
  AssignmentLate as AssignmentLateIcon,
  NotificationImportant as NotificationImportantIcon,
  Edit as EditIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import MonthNavigator from '../common/MonthNavigator';
import { format } from 'date-fns';
import StatCard from '../common/StatCard';
import { useSearchParams } from 'react-router-dom';

const STATUS_OPTIONS = [
  { value: 'ALL', label: 'All Status' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'VALIDATED', label: 'Validated' },
  { value: 'APPROVED', label: 'Approved' },
];

const TimeSheetReviewView = ({
  timesheets,
  loading,
  page,
  rowsPerPage,
  statusFilter,
  searchQuery,
  selectedMonth,
  showAllTimesheets,
  onPageChange,
  onRowsPerPageChange,
  onStatusFilterChange,
  onSearchQueryChange,
  onMonthChange,
  onViewTimesheet,
  onShowAllTimesheetsChange,
  hasMore,
  isEmpty,
  usersWithoutTimesheet,
  onSendReminder,
  onCreateTimesheet,
  reminderCounts,
  lastReminderSentAt,
  userRole,
  missingTimesheetsCount,
  stats,
  statIcons,
}) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const isFiltered = searchParams.toString().length > 0;

  const EmptyState = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 8,
        px: 2,
      }}
    >
      <AssignmentLateIcon sx={{ fontSize: 64, color: 'text.secondary', mb: 2 }} />
      <Typography variant="h6" color="text.secondary" gutterBottom>
        No Timesheets Found
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {searchQuery
          ? 'No timesheets match your search criteria'
          : 'There are no timesheets for the selected filters'}
      </Typography>
    </Box>
  );

  return (
    <Container>
      {isFiltered && (
        <Box sx={{ mb: 2 }}>
          <Chip
            label="Filtered View"
            color="info"
            onDelete={() => {
              window.location.search = '';
            }}
          />
        </Box>
      )}

      <Typography variant="h4" gutterBottom>
        {t('timesheet.review.title')}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <StatCard
            icon={statIcons.totalUsers}
            title={t('timesheet.review.stats.total_users')}
            value={stats.totalUsers}
            subtitle={t('timesheet.review.stats.total_users_subtitle')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            icon={statIcons.timesheetsFound}
            title={t('timesheet.review.stats.timesheets_found')}
            value={stats.timesheetsFound}
            subtitle={t('timesheet.review.stats.timesheets_found_subtitle')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            icon={statIcons.timesheetsMissing}
            title={t('timesheet.review.stats.timesheets_missing')}
            value={stats.timesheetsMissing}
            subtitle={t('timesheet.review.stats.timesheets_missing_subtitle')}
            sx={stats.timesheetsMissing > 0 ? { bgcolor: 'warning.lighter' } : {}}
          />
        </Grid>
      </Grid>

      <Stack spacing={2} sx={{ mb: 3 }}>
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            control={
              <Switch
                checked={showAllTimesheets}
                onChange={e => onShowAllTimesheetsChange(e.target.checked)}
                name="showAllTimesheets"
              />
            }
            label={t('timesheet.review.show_all')}
          />

          <Box
            sx={{
              opacity: showAllTimesheets ? 0.5 : 1,
              pointerEvents: showAllTimesheets ? 'none' : 'auto',
            }}
          >
            <MonthNavigator
              selectedDate={selectedMonth}
              onChange={onMonthChange}
              disabled={showAllTimesheets}
            />
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="status-filter-label">{t('timesheet.review.status_filter')}</InputLabel>
            <Select
              data-testid="status-filter"
              labelId="status-filter-label"
              value={statusFilter}
              onChange={onStatusFilterChange}
              size="small"
              focused={searchParams.get('status') ? true : undefined}
            >
              {STATUS_OPTIONS.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            data-testid="search-input"
            placeholder={t('timesheet.review.search_placeholder')}
            value={searchQuery}
            onChange={e => onSearchQueryChange(e.target.value)}
            label={t('timesheet.review.search_by_email')}
            variant="outlined"
            size="small"
            sx={{ flex: 1 }}
            focused={searchParams.get('email') ? true : undefined}
          />
        </Stack>
      </Stack>

      {isEmpty ? (
        <EmptyState />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('timesheet.review.columns.user')}</TableCell>
                <TableCell>{t('timesheet.review.columns.month')}</TableCell>
                <TableCell>{t('timesheet.review.columns.status')}</TableCell>
                <TableCell>{t('timesheet.review.columns.total_days')}</TableCell>
                <TableCell align="right">{t('common.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && timesheets.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : timesheets.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {t('timesheet.review.no_timesheets')}
                  </TableCell>
                </TableRow>
              ) : (
                timesheets.map(timesheet => (
                  <TableRow key={timesheet.id}>
                    <TableCell data-testid={`timesheet-email-${timesheet.id}`}>
                      {timesheet.userEmail}
                    </TableCell>
                    <TableCell>
                      {formatMonthYear(new Date(timesheet.month + '-01'), i18n.language)}
                    </TableCell>
                    <TableCell>{t(`timesheet.status.${timesheet.status.toLowerCase()}`)}</TableCell>
                    <TableCell>
                      {timesheet.totalDays
                        ? `${timesheet.totalDays.toFixed(1)} ${t('common.days')}`
                        : `0 ${t('common.day')}`}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        data-testid={`view-timesheet-${timesheet.id}`}
                        onClick={() => onViewTimesheet(timesheet)}
                        color="primary"
                      >
                        {t('common.view')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            data-testid="table-pagination"
            component="div"
            count={hasMore ? -1 : (page + 1) * rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25]}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              'data-testid': 'rows-per-page-select',
            }}
            labelDisplayedRows={({ from, to }) => `${from}-${to}`}
            nextIconButtonProps={{
              disabled: !hasMore || timesheets.length < rowsPerPage,
            }}
          />
        </TableContainer>
      )}

      {!showAllTimesheets && (
        <Box sx={{ mt: 4 }}>
          <Divider sx={{ my: 3 }} />
          <Typography
            variant="h6"
            gutterBottom
            color="warning.main"
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <NotificationsIcon color="warning" />
            <Box component="span" sx={{ mr: '25px' }}>
              {t('timesheet.review.missing_timesheets')}
            </Box>
            <Badge
              badgeContent={missingTimesheetsCount}
              color="warning"
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '0.8rem',
                  height: '22px',
                  minWidth: '22px',
                  borderRadius: '11px',
                  transform: 'scale(1) translate(0, -50%)',
                },
              }}
            />
          </Typography>
          {usersWithoutTimesheet.length > 0 ? (
            <Paper>
              <List>
                {usersWithoutTimesheet.map(user => (
                  <ListItem key={user.email}>
                    <ListItemText
                      primary={user.email}
                      secondary={t('timesheet.review.no_timesheet_for_month')}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {reminderCounts[user.email.toLowerCase().replace('@', '_at_')] > 0 && (
                        <Box
                          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                        >
                          <Chip
                            label={t('timesheet.review.reminders_sent', {
                              count: reminderCounts[user.email.toLowerCase().replace('@', '_at_')],
                            })}
                            color="warning"
                            size="small"
                          />
                          {lastReminderSentAt[user.email.toLowerCase().replace('@', '_at_')] && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{ fontSize: '0.7rem', mt: 0.5 }}
                            >
                              {format(
                                new Date(
                                  lastReminderSentAt[user.email.toLowerCase().replace('@', '_at_')]
                                ),
                                'dd/MM/yyyy HH:mm'
                              )}
                            </Typography>
                          )}
                        </Box>
                      )}
                      <ButtonGroup size="small">
                        <Tooltip title={t('timesheet.review.send_reminder')}>
                          <Button
                            onClick={() => onSendReminder(user.email)}
                            startIcon={<NotificationImportantIcon />}
                          >
                            {t('timesheet.review.remind')}
                          </Button>
                        </Tooltip>
                        {userRole !== 'user' && (
                          <Tooltip title={t('timesheet.review.create_timesheet')}>
                            <Button
                              onClick={() => onCreateTimesheet(user.email)}
                              startIcon={<EditIcon />}
                              color="primary"
                            >
                              {t('timesheet.review.create')}
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Paper>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              {t('timesheet.review.all_users_have_timesheets')}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

TimeSheetReviewView.propTypes = {
  timesheets: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  statusFilter: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
  selectedMonth: PropTypes.instanceOf(Date).isRequired,
  showAllTimesheets: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  onSearchQueryChange: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onViewTimesheet: PropTypes.func.isRequired,
  onShowAllTimesheetsChange: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  usersWithoutTimesheet: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSendReminder: PropTypes.func.isRequired,
  onCreateTimesheet: PropTypes.func.isRequired,
  reminderCounts: PropTypes.objectOf(PropTypes.number).isRequired,
  lastReminderSentAt: PropTypes.objectOf(PropTypes.string).isRequired,
  userRole: PropTypes.string.isRequired,
  missingTimesheetsCount: PropTypes.number.isRequired,
  stats: PropTypes.shape({
    totalUsers: PropTypes.number.isRequired,
    timesheetsFound: PropTypes.number.isRequired,
    timesheetsMissing: PropTypes.number.isRequired,
  }).isRequired,
  statIcons: PropTypes.shape({
    totalUsers: PropTypes.node.isRequired,
    timesheetsFound: PropTypes.node.isRequired,
    timesheetsMissing: PropTypes.node.isRequired,
  }).isRequired,
};

export default TimeSheetReviewView;
