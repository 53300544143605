import React from 'react';
import PropTypes from 'prop-types';
import { getWeek, getDay } from 'date-fns';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const getColorForValue = value => {
  if (value === 0) return '#ffffff'; // white for zero

  // Define base green color with different opacities
  const opacity = Math.min(value / 10, 1); // normalize value, max intensity at 10

  // Use a more vibrant green gradient
  const r = Math.round(220 - 144 * opacity); // from 220 to 76
  const g = Math.round(242 - 67 * opacity); // from 242 to 175
  const b = Math.round(220 - 140 * opacity); // from 220 to 80

  return `rgb(${r}, ${g}, ${b})`; // green gradient from light to dark
};

const styles = StyleSheet.create({
  calendarContainer: {
    marginTop: 10,
    marginBottom: 20,
    padding: 8,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
  },
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e0e0e0',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    height: 20,
    backgroundColor: '#ffffff',
  },
  headerRow: {
    backgroundColor: '#f5f5f5',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    height: 20,
  },
  weekCell: {
    position: 'relative',
    width: '10%',
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    padding: 3,
    minWidth: 25,
  },
  dayCell: {
    position: 'relative',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#e0e0e0',
    padding: 3,
    minWidth: 30,
  },
  lastCell: {
    borderRightWidth: 0,
  },
  headerText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 6,
    fontSize: 7,
    color: '#1a237e',
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    width: '100%',
  },
  weekText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 6,
    fontSize: 7,
    color: '#666666',
    fontFamily: 'Helvetica',
    textAlign: 'center',
    width: '100%',
  },
  valueText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 6,
    fontSize: 7,
    color: '#000000',
    fontFamily: 'Helvetica',
    textAlign: 'center',
    width: '100%',
  },
  lastWeekRow: {
    borderBottomWidth: 0,
  },
});

const WeeklyCalendarView = ({ consultant, period }) => {
  const { t } = useTranslation();

  console.log('WeeklyCalendarView - Input Data:', {
    consultant,
    period,
    consultantDays: consultant.days,
  });

  // Create weekdays header array (Mon-Sun)
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day =>
    t(`common.days.short.${day.toLowerCase()}`)
  );

  // First, analyze the data to find the actual week range
  let minWeek = 53; // Initialize with max possible week
  let maxWeek = 0; // Initialize with min possible week

  consultant.days?.forEach(day => {
    const date = new Date(day.date);
    const weekNum = getWeek(date);
    minWeek = Math.min(minWeek, weekNum);
    maxWeek = Math.max(maxWeek, weekNum);
  });

  console.log('Actual data week range:', { minWeek, maxWeek });

  // Group days by week and weekday
  const weeklyData = {};

  // Generate weeks array based on actual data range
  let weeks;
  if (maxWeek < minWeek) {
    // Handle year boundary
    const lastWeekOfYear = 52;
    weeks = [
      ...Array.from({ length: lastWeekOfYear - minWeek + 1 }, (_, i) => minWeek + i),
      ...Array.from({ length: maxWeek }, (_, i) => i + 1),
    ];
  } else {
    weeks = Array.from({ length: maxWeek - minWeek + 1 }, (_, i) => minWeek + i);
  }

  console.log('Weeks to display:', weeks);

  // Initialize all weeks with zeros
  weeks.forEach(weekNum => {
    weeklyData[weekNum] = Array(7).fill(0);
  });

  console.log('Initial weeklyData:', weeklyData);

  // Process the consultant's days
  consultant.days?.forEach(day => {
    const date = new Date(day.date);
    const weekNum = getWeek(date);
    const dayNum = getDay(date) || 7; // Convert Sunday from 0 to 7

    console.log('Processing day:', {
      date,
      originalDate: day.date,
      weekNum,
      dayNum,
      value: day.value,
      hasWeek: !!weeklyData[weekNum],
    });

    if (weeklyData[weekNum]) {
      weeklyData[weekNum][dayNum - 1] = day.value;
    }
  });

  console.log('Final weeklyData:', weeklyData);

  const totalRows = weeks.length + 1;
  const rowHeight = 20;
  const containerHeight = totalRows * rowHeight + 16;

  return (
    <View style={[styles.calendarContainer, { height: containerHeight }]}>
      <View style={styles.table}>
        <View style={[styles.row, styles.headerRow]}>
          <View style={styles.weekCell}>
            <Text style={styles.headerText}>{t('timesheet.pdf.timesheet.week')}</Text>
          </View>
          {weekDays.map((day, index) => (
            <View key={day} style={[styles.dayCell, index === 6 && styles.lastCell]}>
              <Text style={styles.headerText}>{day}</Text>
            </View>
          ))}
        </View>

        {weeks.map((week, index) => (
          <View key={week} style={[styles.row, index === weeks.length - 1 && styles.lastWeekRow]}>
            <View style={styles.weekCell}>
              <Text style={styles.weekText}>W{week}</Text>
            </View>
            {(weeklyData[week] || Array(7).fill(0)).map((value, index) => (
              <View
                key={index}
                style={[
                  styles.dayCell,
                  index === 6 && styles.lastCell,
                  { backgroundColor: getColorForValue(value) },
                ]}
              >
                <Text style={styles.valueText}>
                  {value ? (value === 1 ? '1' : value.toFixed(1)) : '0'}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

WeeklyCalendarView.propTypes = {
  consultant: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    totalDays: PropTypes.number.isRequired,
    days: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  period: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

export default WeeklyCalendarView;
