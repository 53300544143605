import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmployeeSetupDialog = ({ open, onClose, initialData = null }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('admin.users.setup.title')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          {/* Email - Read only */}
          <TextField
            label={t('admin.users.columns.email')}
            value={initialData?.email || ''}
            disabled
            fullWidth
          />

          {/* First Name - Read only */}
          <TextField
            label={t('admin.users.columns.firstName')}
            value={initialData?.firstName || '-'}
            disabled
            fullWidth
          />

          {/* Last Name - Read only */}
          <TextField
            label={t('admin.users.columns.lastName')}
            value={initialData?.lastName || '-'}
            disabled
            fullWidth
          />

          {/* Entry Date - Read only */}
          <TextField
            label={t('admin.users.fields.entryDate')}
            value={
              initialData?.entryDate ? new Date(initialData.entryDate).toLocaleDateString() : '-'
            }
            disabled
            fullWidth
          />

          {/* Contract Type - Read only */}
          <TextField
            label={t('admin.users.fields.contractType')}
            value={
              initialData?.contractType
                ? t(`admin.users.contractTypes.${initialData.contractType}`)
                : '-'
            }
            disabled
            fullWidth
          />

          {/* Active Status - Read only */}
          <FormControlLabel
            control={<Switch checked={initialData?.isActive !== false} disabled />}
            label={t(
              initialData?.isActive !== false
                ? 'admin.users.fields.active'
                : 'admin.users.fields.inactive'
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-testid="close-button">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmployeeSetupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    entryDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    contractType: PropTypes.string,
    isActive: PropTypes.bool,
  }),
};

export default EmployeeSetupDialog;
