/**
 * @component ProtectedRoute
 * @description Route wrapper component that handles authentication and role-based access control.
 * Redirects unauthorized users to login or home page based on their permissions.
 */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useCoachees } from '../../hooks/useCoachees';
import PropTypes from 'prop-types';

const roleHierarchy = {
  user: 0,
  rh: 1,
  superadmin: 2,
};

/**
 * @typedef {Object} ProtectedRouteProps
 * @property {React.ReactNode} children - Child components to render if authorized
 * @property {string} [requiredRole] - Optional role requirement for access
 * @property {boolean} [allowCoach] - Optional flag to allow coaches access
 */
const ProtectedRoute = ({ children, requiredRole, allowCoach = false }) => {
  const { user, loading } = useAuth();
  const coachees = useCoachees(user?.email);
  const isCoach = coachees.length > 0;

  // Show nothing while checking authentication
  if (loading) return null;

  // Redirect to login if not authenticated
  if (!user) return <Navigate to="/login" />;

  // Check role-based access if required
  if (requiredRole) {
    const userRoleLevel = roleHierarchy[user.role] || 0;
    const requiredRoleLevel = roleHierarchy[requiredRole] || 0;

    // User has access if their role level is >= required level OR they are a coach (when allowCoach is true)
    const hasRequiredRole = userRoleLevel >= requiredRoleLevel;
    const hasAccess = hasRequiredRole || (allowCoach && isCoach);

    return hasAccess ? children : <Navigate to="/" />;
  }

  return children;
};

// PropTypes validation
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  requiredRole: PropTypes.oneOf(['user', 'rh', 'superadmin']),
  allowCoach: PropTypes.bool,
};

export default ProtectedRoute;
