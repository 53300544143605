/**
 * Checks if a user is authorized to access a mission
 * @param {Object} mission - The mission object to check
 * @param {string} userEmail - The email of the user
 * @param {boolean} isSuperAdmin - Whether the user is a super admin
 * @returns {boolean} - Whether the user is authorized
 */
export const isUserAuthorizedForMission = (mission, userEmail, isSuperAdmin) => {
  if (isSuperAdmin) return true;

  // If mission is available to everyone
  if (mission.isAvailableToEveryone) return true;
  // Check if user is in authorized users
  return mission.authorizedUsers.includes(userEmail);
};

/**
 * Filters missions based on user authorization
 * @param {Array} missions - Array of missions to filter
 * @param {string} userEmail - The email of the user
 * @param {boolean} isSuperAdmin - Whether the user is a super admin
 * @returns {Array} - Filtered array of missions
 */
export const filterAuthorizedMissions = (missions, userEmail, isSuperAdmin) => {
  return missions.filter(mission => isUserAuthorizedForMission(mission, userEmail, isSuperAdmin));
};
