/**
 * @component ProjectDialogView
 * @description Presentation component for project creation/editing dialog.
 * Renders form fields for project details and mission management.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MissionItem } from './index';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from '../../utils/dateFormatters';

/**
 * @typedef {Object} ProjectDialogViewProps
 * @property {boolean} open - Dialog visibility state
 * @property {Function} onClose - Dialog close handler
 * @property {Object} project - Project data for editing
 * @property {Object} formData - Form field values
 * @property {Function} onFormChange - Form field change handler
 * @property {Function} onSubmit - Form submission handler
 * @property {Function} onAddMission - Mission addition handler
 * @property {Function} onEditMission - Mission edit handler
 * @property {Function} onDeleteMission - Mission deletion handler
 * @property {Array} missions - Project missions list
 */
const ProjectDialogView = ({
  open,
  onClose,
  project,
  formData,
  onFormChange,
  onSubmit,
  onAddMission,
  onEditMission,
  onDeleteMission,
  missions,
  isEdit,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t(isEdit ? 'management.project.title.edit' : 'management.project.title.new')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label={t('management.project.fields.code')}
            name="code"
            value={formData.code}
            onChange={e => onFormChange('code', e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('management.project.fields.name')}
            name="name"
            value={formData.name}
            onChange={e => onFormChange('name', e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('management.project.fields.contract_number')}
            name="contractNumber"
            value={formData.contractNumber}
            onChange={e => onFormChange('contractNumber', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('management.project.fields.purchase_order')}
            name="purchaseOrder"
            value={formData.purchaseOrder}
            onChange={e => onFormChange('purchaseOrder', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            type="number"
            label={t('management.project.fields.total_budget')}
            name="totalBudget"
            value={formData.totalBudget}
            onChange={e => onFormChange('totalBudget', Number(e.target.value))}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('management.project.fields.start_date')}
            type="date"
            value={formData.startDate}
            onChange={e => onFormChange('startDate', e.target.value)}
            required
            placeholder={formatShortDate(new Date(), i18n.language)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            select
            label={t('management.project.fields.status')}
            value={formData.status}
            onChange={e => onFormChange('status', e.target.value)}
            required
            fullWidth
            sx={{ mb: 2 }}
          >
            <MenuItem value="ACTIVE">{t('management.project.status.active')}</MenuItem>
            <MenuItem value="ON_HOLD">{t('management.project.status.on_hold')}</MenuItem>
            <MenuItem value="COMPLETED">{t('management.project.status.completed')}</MenuItem>
          </TextField>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, mb: 2 }}>
            <Tooltip title={t('management.project.fields.invoicable_description')} placement="top">
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.invoicable}
                    onChange={e => onFormChange('invoicable', e.target.checked)}
                    color="primary"
                  />
                }
                label={t('management.project.fields.invoicable')}
              />
            </Tooltip>

            <Tooltip title={t('management.project.fields.forfait_description')} placement="top">
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.forfait}
                    onChange={e => onFormChange('forfait', e.target.checked)}
                    disabled={!formData.invoicable}
                    color="primary"
                  />
                }
                label={t('management.project.fields.forfait')}
              />
            </Tooltip>
          </Box>
        </Box>

        {/* Missions section */}
        <Typography variant="h6" sx={{ mt: 3, mb: 2, px: 3 }}>
          {t('management.mission.sections.missions')}
        </Typography>

        {/* Missions table */}
        <TableContainer component={Paper} sx={{ mx: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('management.mission.fields.name')}</TableCell>
                <TableCell>{t('management.mission.fields.description')}</TableCell>
                <TableCell align="right">{t('management.mission.fields.total_days')}</TableCell>
                <TableCell align="right">{t('management.mission.fields.daily_rate')}</TableCell>
                <TableCell align="right">{t('common.total')}</TableCell>
                <TableCell align="center">{t('common.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(!missions || missions.length === 0) && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {t('management.mission.sections.no_missions')}
                  </TableCell>
                </TableRow>
              )}
              {missions.map((mission, index) => (
                <MissionItem
                  key={index}
                  mission={mission}
                  onEdit={() => onEditMission(index)}
                  onDelete={() => onDeleteMission(index)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button startIcon={<AddIcon />} onClick={onAddMission} sx={{ mt: 2, ml: 3 }}>
          {t('management.mission.title.new')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button onClick={onSubmit} variant="contained">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// PropTypes validation
ProjectDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object,
  formData: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddMission: PropTypes.func.isRequired,
  onEditMission: PropTypes.func.isRequired,
  onDeleteMission: PropTypes.func.isRequired,
  missions: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default ProjectDialogView;
