import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const CoachAssignmentDialogView = ({
  open,
  onClose,
  onSubmit,
  formData,
  onFormChange,
  availableCoaches,
  userEmail,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="coach-assignment-dialog"
    >
      <DialogTitle>{t('admin.users.coach_assignment.title', { email: userEmail })}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
          <Autocomplete
            multiple
            options={availableCoaches}
            value={formData.coaches}
            onChange={(_, newValue) => onFormChange('coaches', newValue)}
            getOptionLabel={option => {
              if (typeof option === 'string') return option;
              return option.firstName && option.lastName
                ? `${option.firstName} ${option.lastName} (${option.email})`
                : option.email;
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={t('admin.users.coach_assignment.coaches')}
                placeholder={t('admin.users.coach_assignment.select_coaches')}
                data-testid="coaches-autocomplete"
              />
            )}
            isOptionEqualToValue={(option, value) => {
              if (typeof value === 'string') return option.email === value;
              return option.email === value.email;
            }}
            data-testid="coaches-input"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-testid="coach-dialog-cancel">
          {t('common.cancel')}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          data-testid="coach-dialog-submit"
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CoachAssignmentDialogView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    coaches: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  availableCoaches: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ).isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default CoachAssignmentDialogView;
